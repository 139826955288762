<template>
  <v-container fluid>
    <Header />
    <v-container page>
      <h2>Cookie policy</h2>
      <p class="mt-40">
        ll sito aria.ambiente.piemonte.it fa uso delle seguenti tipologie di
        cookie:
      </p>
      <ul>
        <li class="mt-40">
          <strong>cookie tecnici:</strong>&nbsp; sono necessari per consentire
          la navigazione sicura ed efficiente dei siti; sono cookie di sessione
          o persistenti, in generale cookie di dominio, come cookie di
          preferenze utente e cookie di monitoraggio. L'utilizzo dei cookie
          tecnici viene fatto in modo da evitare il ricorso ad altre tecniche
          informatiche potenzialmente pregiudizievoli per la riservatezza della
          navigazione degli utenti, in quanto non consentono l'acquisizione di
          dati personali identificativi dell'utente.
        </li>
        <li class="mt-40">
          <strong>cookie analytics [Matomo]</strong>: al fine di facilitare la
          navigazione nel sito e il modo in cui i visitatori ci arrivano, il
          sito utilizza <strong>Matomo</strong>, un software open source di
          analisi web che garantisce piena compatibilità con le leggi europee
          relative alla protezione dei dati. Per analizzare i punti di maggiore
          interesse dei siti visitati dall’utente, Matomo utilizza i cookie a
          scopi statistici e raccoglie informazioni in sola forma aggregata,
          senza associare l'indirizzo IP di navigazione dell'utente a nessun
          altro dato raccolto precedentemente. Sono stati adottati strumenti che
          riducono il potere identificativo dei cookie tramite il mascheramento
          di porzioni significative dell'IP. Sul sito&nbsp;<a
            href="https://webanalytics.italia.it/privacy"
            target="_blank"
            rel="nofollow"
            >Web Analytics Italia</a
          >&nbsp;è possibile consultare la policy relativa alla privacy.
        </li>
      </ul>
      <p>
        La navigazione sul sito di aria.ambiente.piemonte.it comporta
        l’accettazione delle condizioni d’uso, comprese le funzionalità legate
        ai cookie che possono essere disabilitate utilizzando le opzioni
        specifiche presenti nei vari tipi di browser.
      </p>
      <h2>Come disabilitare i cookie (opt-out)</h2>
      <p>
        La maggior parte dei browser accettano i cookies automaticamente, ma è
        possibile rifiutarli. Se non si desidera ricevere o memorizzare i
        cookie, si possono modificare le impostazioni di sicurezza del browser
        (Internet Explorer, Google Chrome, Mozilla Firefox, Safari Opera,
        ecc...). Ciascun browser presenta procedure diverse per la gestione
        delle impostazioni.
      </p>
      <p>
        Normalmente è necessario accedere alla sezione
        “opzioni/impostazioni/preferenze avanzate” del browser e qui accedere ad
        una sezione intitolata “Privacy/Sicurezza/Dati”. A seconda del browser,
        è possibile bloccare tutti i cookie, scegliere di bloccare solo quelli
        delle terze parti oppure ancora far sì che i cookie vengano cancellati
        alla chiusura del browser.
      </p>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";

export default {
  name: "Home",
  components: { Header },
  data: () => ({
    snackbar: false
  }),
  methods: {
    disableTracking: function() {
      this.$ga.disable();
      this.snackbar = true;
    }
  }
};
</script>
